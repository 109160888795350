import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Currency } from '../currency.interface';
import axios from "axios";
import { ModalDirective } from 'angular-bootstrap-md';
import { PixCob } from '../pix-cob';
import { InteractionService } from 'src/app/interaction.service';
import { Router } from '@angular/router';
import { ModalsService } from '../services/modals/modals.service';
import { EUserActionsModalType } from '../Interfaces/Enumerators/EUserActionsModalType';

declare var PagSeguroLightbox: any;

interface TransactionGerencianet {
  code: number,
  data: {
    charge_id: number,
    created_at: Date,
    custom_id: string,
    status: string;
    total: number
  }
}

interface LinkGerencianet {
  code: number,
  data: {
    charge_id: number,
    status: string,
    total: number,
    custom_id: string,
    payment_url: string,
    payment_method: string,
    conditional_discount_date: string,
    request_delivery_address: boolean,
    message: string,
    expire_at: Date,
    created_at: Date
  }
}

interface CheckoutPagseguro {
  checkout: {
    code: string,
    date: Date
  }
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})

export class Payment implements OnInit {
  @ViewChild('framePix', { static: true }) modalPix: ModalDirective;

  constructor(private interactionService: InteractionService, private configService: ConfigService, private appComponent: AppComponent,
    private translate: TranslateService, private router: Router, private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  public payPalConfig?: IPayPalConfig;
  credit: string = "0";
  Currency: Currency = <Currency>{};
  userCountry: string = localStorage.getItem('currentUserCountry');

  qrCode: string = 'http://www.poolbet365.com';

  tokenGnApiPix;
  screen = 1;

  ngOnInit() {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    if (localStorage.getItem('currentUserToken') == null) {
      this.appComponent.nextUrl = '/payment';
      this.modalsService.openUserActionsModal(EUserActionsModalType.Login);
    }
  }
  Pay() {
    this.credit = (<HTMLInputElement>document.getElementById("credit")).value;
    if (this.credit == '') {
      this.modalsService.openNotificationModal(this.translate.instant('Informe o valor desejado'), this.translate.instant('valor desejado é obrigatorio'), true);
    }
    else {
      this.credit = (<HTMLInputElement>document.getElementById("credit")).value;
      if (this.Currency.currency == 'USD' || this.Currency.currency == 'EUR' || this.Currency.currency == 'AUD' || this.Currency.currency == 'CAD' || this.Currency.currency == 'CZK' || this.Currency.currency == 'DKK' || this.Currency.currency == 'HKD' || this.Currency.currency == 'HUF' || this.Currency.currency == 'INR' || this.Currency.currency == 'ILS' || this.Currency.currency == 'JPY' || this.Currency.currency == 'MYR' || this.Currency.currency == 'MXN' || this.Currency.currency == 'TWD' || this.Currency.currency == 'NZD' || this.Currency.currency == 'NOK' || this.Currency.currency == 'PHP' || this.Currency.currency == 'PLN' || this.Currency.currency == 'GBP' || this.Currency.currency == 'RUB' || this.Currency.currency == 'SGD' || this.Currency.currency == 'SEK' || this.Currency.currency == 'CHF' || this.Currency.currency == 'THB') {

      }
      else {
        this.Currency.currency = 'BRL'
      }
      this.payPalConfig = {
        currency: this.Currency.currency,
        clientId: 'AfSxu6ph4ZA9kGwG3e2gJecWuFUiCUPdlyn8wUD6CXMNBPh9kqVX84uP70bB7VDE9mo-PpdbNMLvoQrE&locale=pt_BR',
        createOrderOnClient: (data) => <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [{
            amount: {
              currency_code: this.Currency.currency,
              value: this.credit,
              breakdown: {
                item_total: {
                  currency_code: this.Currency.currency,
                  value: this.credit
                }
              }
            },
            items: [{
              name: 'Enterprise Subscription',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: this.Currency.currency,
                value: this.credit,
              },
            }]
          }]
        },
        advanced: {
          commit: 'true'
        },
        style: {
          label: 'paypal',
          layout: 'vertical',
          size: 'small',
          color: 'blue',
          shape: 'rect'
        },

        onApprove: (data, actions) => {
          // console.log('onApprove - transaction was approved, but not authorized', data, actions);
          actions.order.get().then(details => {
            //console.log('onApprove - you can get full order details inside onApprove: ', details);
          });

        },
        onClientAuthorization: (data) => {
          // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
          if (this.Currency.currency == 'USD' || this.Currency.currency == 'EUR' || this.Currency.currency == 'AUD' || this.Currency.currency == 'CAD' || this.Currency.currency == 'CZK' || this.Currency.currency == 'DKK' || this.Currency.currency == 'HKD' || this.Currency.currency == 'HUF' || this.Currency.currency == 'INR' || this.Currency.currency == 'ILS' || this.Currency.currency == 'JPY' || this.Currency.currency == 'MYR' || this.Currency.currency == 'MXN' || this.Currency.currency == 'TWD' || this.Currency.currency == 'NZD' || this.Currency.currency == 'NOK' || this.Currency.currency == 'PHP' || this.Currency.currency == 'PLN' || this.Currency.currency == 'GBP' || this.Currency.currency == 'RUB' || this.Currency.currency == 'SGD' || this.Currency.currency == 'SEK' || this.Currency.currency == 'CHF' || this.Currency.currency == 'THB') {
            this.addUserCredit(this.appComponent.userAdmin.id, Number(this.Currency.quote), 1);
          }
          else {
            this.addUserCredit(this.appComponent.userAdmin.id, Number(this.credit), 1);
          }

        },
        onCancel: (data, actions) => {
          //  console.log('OnCancel', data, actions);


        },
        onError: err => {
          // console.log('OnError', err);

        },
        onClick: (data, actions) => {
          // console.log('onClick', data, actions);

        },
      };

    }
  }

  AdjustCurrency() {
    var element = this.configService.formatCurrencyValue(5)
    return element;
  }

  TakeLocation() {
    if (this.translate.currentLang == "pt" || this.translate.currentLang == undefined) return true
    else return false
  }

  addUserCredit(fromUserId: number, creditToTransfer: Number, transactionType: number) {
    this.configService.addUserCredit(fromUserId, creditToTransfer, transactionType)
      .subscribe(data => {
        this.appComponent.userAdmin.credit = this.appComponent.userAdmin.credit + creditToTransfer;
        this.modalsService.openNotificationModal(this.translate.instant("pg-credit-transfer.transfer-done"), this.translate.instant("pg-credit-transfer.transfer-done-info"), false, '/payment');
        this.ngOnInit();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-credit-transfer.transfer-failed"), this.translate.instant("pg-credit-transfer.transfer-failed-info"), true);
        console.log(error);
        this.ngOnInit();
      });
  }

  creditGerencianet() {
    this.credit = (<HTMLInputElement>document.getElementById("credit")).value;
    if (this.credit == '') {
      this.modalsService.openNotificationModal(this.translate.instant('Informe o valor desejado'), this.translate.instant('valor desejado é obrigatorio'), true);
    }
    else {
      this.credit += "00";
      this.configService.createGerencianetTransaction(+this.credit, this.appComponent.userAdmin.id)
        .subscribe((data: TransactionGerencianet) => {
          //console.log(data);
          this.configService.generateLinkGerencianet(data.data.charge_id, "Seu crédito será carregado após recebermos confirmação do pagamento.")
            .subscribe((info: LinkGerencianet) => {
              //console.log(info);
              window.open(info.data.payment_url, "_blank", "fullscreen=true");
              this.modalsService.openNotificationModal(this.translate.instant("pg-credit-transfer.transfer-done"),
              "O prazo de entrada de crédito é de 2 horas para pagamento por cartão e 48 horas utéis para pagamento via boleto bancário. Caso o crédito não entre após esse período, favor entrar em contato com nossa assistência.", false, '/payment');
            }, err => {
              console.log(err);
              this.modalsService.openNotificationModal(this.translate.instant("pg-credit-transfer.transfer-failed"), this.translate.instant("pg-credit-transfer.transfer-failed-info"), true);
            });
        }, error => {
          console.log(error);
          this.modalsService.openNotificationModal(this.translate.instant("pg-credit-transfer.transfer-failed"), this.translate.instant("pg-credit-transfer.transfer-failed-info"), true);
        })
    }
  }

  creditPagseguro() {
    this.credit = (<HTMLInputElement>document.getElementById("credit")).value;
    if (this.credit == '') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-payment.fail-empty-value'), this.translate.instant('pg-payment.fail-empty-value-info'), true);
    }
    else if (Number.parseInt(this.credit) < 5) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-payment.fail-empty-value'), this.translate.instant('pg-payment.fail-empty-value-info'), true);
    }
    else {
      this.configService.checkoutPagseguro(+this.credit, this.appComponent.userAdmin.id)
        .subscribe((data: CheckoutPagseguro) => {
          //this.lightboxPagseguroEvents(data[0].checkout.code);
          //window.open("https://sandbox.pagseguro.uol.com.br/v2/checkout/payment.html?code="+data[0].checkout.code,"_blank", "fullscreen=true");
          window.open("https://pagseguro.uol.com.br/v2/checkout/payment.html?code=" + data[0].checkout.code, "_blank", "fullscreen=true");
          this.modalsService.openNotificationModal(this.translate.instant("pg-payment.success-transaction"), this.translate.instant("pg-payment.success-transaction-info"), false, '/payment');
          (<HTMLInputElement>document.getElementById("credit")).value = '';
          this.credit = '';
        }, error => {
          console.log(error);
          this.modalsService.openNotificationModal(this.translate.instant("pg-payment.fail-load-credit"), this.translate.instant("pg-payment.fail-load-credit-info"), true);
        });
    }
  }


  lightboxPagseguroEvents(checkoutCode: string) {
    //Insira o código de checkout gerado no Passo 1
    var code = checkoutCode;
    var callback = {
      success: function (transactionCode) {
        this.modalsSerivce.openNotificationModal(this.translate.instant("pg-payment.success-transaction"), this.translate.instant("pg-payment.success-transaction-info"), false);
      },
      abort: function () {
        this.modalsService.openNotificationModal(this.translate.instant("pg-payment.fail-load-credit"), this.translate.instant("pg-payment.fail-load-credit-info"), true);
      }
    };
    //Chamada do lightbox passando o código de checkout e os comandos para o callback
    var isOpenLightbox = PagSeguroLightbox(code, callback);
    // Redireciona o comprador, caso o navegador não tenha suporte ao Lightbox
    if (!isOpenLightbox) {
      location.href = "https://pagseguro.uol.com.br/v2/checkout/payment.html?code=" + code;
      //console.log("Redirecionamento")
    }
  }

  // static QR Code
  payPix() {
    this.credit = (<HTMLInputElement>document.getElementById("credit")).value;
    if (this.credit == '') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-payment.fail-empty-value'), this.translate.instant('pg-payment.fail-empty-value'), true);
    }
    else {
      this.router.navigate(['/pix/' + this.credit]);
    }
  }

  // dinamic QR Code
  createPixCob() {
    //this.credit = (<HTMLInputElement>document.getElementById("credit")).value;
    this.credit = "2";
    if (this.credit == '') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-payment.fail-empty-value'), this.translate.instant('pg-payment.fail-empty-value'), true);
    }
    else {
      this.configService.createPixCob(this.appComponent.userAdmin.id, +this.credit)
        .subscribe((data: PixCob) => {
          this.qrCode = data.payload;
        }, error => {
          console.log(error);
        });
      this.modalPix.show();
    }

  }

  createGeneralAPIPIX() {
    this.credit = (<HTMLInputElement>document.getElementById("amount-value")).value;
    if (this.credit == '') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-payment.fail-empty-value'), this.translate.instant('pg-payment.fail-empty-value-info'), true);
      return;
    }
    else if (Number.parseInt(this.credit) < 5) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-payment.fail-empty-value'), this.translate.instant('pg-payment.fail-empty-value-info'), true);
      return;
    }

    this.credit += ".00"; // add decimal format

    this.configService.getGeneralAPIPIXToken(this.appComponent.userAdmin.parentId)
      .subscribe(data => {
        //this.tokenGnApiPix = data.token;
        this.configService.createGeneralAPIPIXPayment(data.token, this.appComponent.userAdmin.id, this.credit, this.appComponent.userAdmin.parentId)
          .subscribe(payment => {

            this.qrCode = payment.qrCode;

            this.credit = this.credit.substring(0, this.credit.length - 3);
            this.screen = 2;
          }, error => {
            console.log("Wasn't possible to get the qr code.");
            console.log(error.message);
          });

      }, error => {
        this.modalsService.openNotificationModal("Fail", error.message, true);
        return;
      });
  }

  copyMessage() {
    let val = this.qrCode;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
